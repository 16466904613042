import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { RootState } from '/store';

export interface INotificationsStore {
  haveUnreadNotifications: boolean;
}

const initialState: INotificationsStore = {
  haveUnreadNotifications: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setUnreadNotifications: (state, action: PayloadAction<boolean>) => {
      state.haveUnreadNotifications = action.payload;
    },
    rehydrate: (state) => {
      _.merge(state, initialState);
    },
  },
});

export const notificationsToolkit = Object.freeze({
  initialState,
  actions: notificationsSlice.actions,
  selectors: {
    state: (state: RootState) => state.notifications,
    rehydrate: (state: RootState) => state.notifications,
    haveUnreadNotifications: (state: RootState) => state.notifications.haveUnreadNotifications,
  },
});

export const notificationsReducer = notificationsSlice.reducer;
