import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { fromEvent } from 'rxjs';
import { useSelector } from 'react-redux';
import { useEventCallback } from '@mui/material';

import { preferredThemeModeLocalStorageKey } from '/constants';
import { StorageContext } from '/storage';
import { useAppDispatch } from '/store/RootState';
import { appToolkit } from '/store/toolkits/app';
import { logout as logoutAction } from '/store/toolkits/auth/actions';
import { FCWithChildren, ThemeMode } from '/common/models';

import { activeSpaceToolkit, authToolkit, IAuthState, IUIState, uiToolkit } from './toolkits';

export const StorePersist: FCWithChildren = ({ children }) => {
  const storage = useContext(StorageContext);
  const auth = useSelector(authToolkit.selectors.state);
  const app = useSelector(appToolkit.selectors.state);
  const space = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const ui = useSelector(uiToolkit.selectors.state);

  const [themeModelessUi, setThemeModelessUi] = useState<IUIState>(ui);
  const [currentUiThemeMode, setCurrentUiThemeMode] = useState<ThemeMode>(ui.currentThemeMode);

  const dispatch = useAppDispatch();
  const logout = useEventCallback(() => dispatch(logoutAction()));
  const handleUiChanges = useCallback(() => {
    const currentThemeMode = ui.currentThemeMode;
    setThemeModelessUi({ ...ui, currentThemeMode });
    setCurrentUiThemeMode(currentThemeMode);
  }, [ui]);

  useEffect(() => {
    // is case user is not loggedIn, logout in focus
    const sub = fromEvent(window, 'focus').subscribe(() => {
      const isLoginPage = window.location.pathname === '/login';
      const isGoogleAuthPage = window.location.pathname === '/auth/google';

      if (isLoginPage || isGoogleAuthPage) {
        return;
      }

      const lAuth = localStorage.getItem('auth');
      const sAuth = sessionStorage.getItem('auth');

      if (sAuth) {
        const { loggedIn } = JSON.parse(sAuth) as IAuthState;
        if (!loggedIn) {
          logout();
          return;
        }

        return;
      }

      if (lAuth) {
        const { loggedIn } = JSON.parse(lAuth) as IAuthState;
        if (!loggedIn) {
          logout();
        }
      }
    });

    return () => sub.unsubscribe();
  }, [logout]);

  useEffect(() => {
    if (auth.rememberMe) {
      localStorage.setItem('auth', JSON.stringify(auth));
      sessionStorage.removeItem('auth');
    } else {
      // set auth to the session store in case user refresh the page
      sessionStorage.setItem('auth', JSON.stringify(auth));
      localStorage.removeItem('auth');
    }
  }, [auth]);

  useEffect(() => {
    if (app.app) {
      localStorage.setItem(`app:${app.app.routeName}`, JSON.stringify(app));
    }
  }, [app]);

  useEffect(handleUiChanges, [handleUiChanges]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (auth.user) {
      localStorage.setItem(`ui:${auth.user.id}`, JSON.stringify(themeModelessUi));
    }
  }, [auth.user, themeModelessUi]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (currentUiThemeMode) {
      localStorage.setItem(preferredThemeModeLocalStorageKey, currentUiThemeMode);
    }
  }, [currentUiThemeMode]);

  useEffect(() => {
    if (space?.id) {
      storage.set('activeSpaceID', space.id.serialize());
    } else {
      storage.remove('activeSpaceID');
    }
  }, [space, storage]);

  return children as ReactElement;
};
