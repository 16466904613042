import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '/store/RootState';

import { clusterState } from './state';

const clusterSlice = createSlice({
  name: 'runForm',
  initialState: clusterState,
  reducers: {
    setFilters: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.filters = { ...state.filters, [action.payload.name]: action.payload.value };
    },
  },
});
const baseSelectors = {
  filters: createSelector(
    (state: RootState) => state.cluster.filters,
    (filters) => filters
  ),
  // deviceState: (state: RootState) => state.devices,
  // streamEvents: (state: RootState) => state.devices.streamEvents,
};
export const clusterToolkit = Object.freeze({
  clusterState,
  actions: clusterSlice.actions,
  selectors: {
    ...baseSelectors,
  },
});
export const clusterModuleReducer = clusterSlice.reducer;
