import {
  activeSpaceReducer,
  appReducer,
  authReducer,
  calendarReducer,
  currentModuleReducer,
  deviceModuleReducer,
  kbarReducer,
  notificationsReducer,
  uiReducer,
  clusterModuleReducer,
} from './toolkits';

export const reducer = {
  notifications: notificationsReducer,
  kbar: kbarReducer,
  ui: uiReducer,
  activeSpace: activeSpaceReducer,
  auth: authReducer,
  app: appReducer,
  currentModule: currentModuleReducer,
  devices: deviceModuleReducer,
  calendar: calendarReducer,
  cluster: clusterModuleReducer,
};
